import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSession} from '../../contexts/SessionContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {useApi} from '../../contexts/ApiContext';

const StoreImpersonateUserNotice = () => {
  const [sessionState, sessionActions] = useSession();
  const [state] = useEcommerce();
  const {t} = useTranslation('pages');
  const api = useApi();

  const IMPERSONATE = `bl:v2:${state.uid}:impersonate`;
  const isImpersonate = window.localStorage.getItem(IMPERSONATE);

  if (!isImpersonate) {
    return null;
  }

  const handleLogout = () => {
    api.logout();
    sessionActions.finish();
    window.close();
  };

  return (
    <div className="w-100 text-center p-1 bg-primary">
      <p className="mb-0">
        {t('StorePage.StoreImpersonateUserNotice.message')}
        <strong>
          {sessionState.data.user.username}
          {sessionState.data.user.info.company_name
            ? ` (${sessionState.data.user.info.company_name})`
            : null}
        </strong>{' '}
        - {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={(e) => {
            e.preventDefault();
            handleLogout();
          }}
          href="#"
          style={{cursor: 'pointer', color: 'inherit'}}
        >
          {t('StorePage.StoreImpersonateUserNotice.logoutMessage')}
        </a>
      </p>
    </div>
  );
};

export default StoreImpersonateUserNotice;
