import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {useParams} from 'react-router';
import {useApi} from '../../contexts/ApiContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import VolumeDiscounts from '../../components/ShopProduct/VolumeDiscounts';
import {CombinationsMatrixAndFilters} from '../../components/ShopProduct';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';
import CarouselProduct from './CarouselProduct';
import {ProductTitle, ProductTitleMobile} from './ProductTitle';
import ProductPrice from './ProductPrice';
import ProductDescription from './ProductDescription';
import {CombinationQuantitySetter, ShopProduct} from '../../types/ShopProduct';
import ProductHighlight from './ProductHighlight';
import {useSession} from '../../contexts/SessionContext';
import {customerAllowedToSeePrices} from '../../utils';

interface StoreProductViewProps {
  product: ShopProduct;
  currency: string;
  taxesEnabled: boolean;
  hidePrices: boolean;
  setQuantityItem: CombinationQuantitySetter;
}

const StoreProductDesktopView = ({
  product,
  currency,
  taxesEnabled,
  hidePrices,
  setQuantityItem,
}: StoreProductViewProps) => (
  <React.Fragment>
    <ProductTitle title={product.name} sku={product.ref} showClose />
    <ProductPrice
      product={product}
      currency={currency}
      taxesEnabled={taxesEnabled}
      hidePrice={hidePrices}
    />
    <VolumeDiscounts
      product={product}
      currency={currency}
      setCombinationQuantity={setQuantityItem}
      direction="row"
      hidePrices={hidePrices}
    />
    <ProductHighlight product={product} />
    <ProductDescription product={product} />
    {product.combinations.length > 1 && (
      <CombinationsMatrixAndFilters
        product={product}
        currency={currency}
        setCombinationQuantity={setQuantityItem}
        hidePrices={hidePrices}
      />
    )}
  </React.Fragment>
);

const StoreProductMobileView = ({
  product,
  currency,
  taxesEnabled,
  hidePrices,
  setQuantityItem,
}: StoreProductViewProps) => (
  <React.Fragment>
    <ProductTitleMobile product={product} setCombinationQuantity={setQuantityItem} />
    <ProductPrice
      product={product}
      currency={currency}
      taxesEnabled={taxesEnabled}
      hidePrice={hidePrices}
    />
    <ProductHighlight product={product} />
    <ProductDescription product={product} />
    <VolumeDiscounts product={product} currency={currency} direction="row" />
    {product.combinations.length > 1 && (
      <CombinationsMatrixAndFilters
        product={product}
        currency={currency}
        setCombinationQuantity={setQuantityItem}
      />
    )}
  </React.Fragment>
);

const StoreProductPage = () => {
  const api = useApi();
  const [state, actions] = useEcommerce();
  const [, , selectors] = useSession();
  const {id} = useParams();
  const [product, setProduct] = useState<ShopProduct | null>(null);
  const {isMobile} = useDeviceWindow();

  const {uid} = state;

  const hidePrices = !customerAllowedToSeePrices(state.settings, selectors);

  const setQuantityItem = useCallback(
    async (productId: number, combinationId: string, quantity: number) => {
      const result = await api.post(`/blink/${uid}/${productId}/`, {
        combination: combinationId,
        quantity,
      });

      setProduct((prevState) => ({
        ...prevState,
        ...result.product,
      }));

      actions.setCart(result.cart);
    },
    [api, uid, actions],
  );

  useEffect(() => {
    const fetchProduct = async () => {
      const resultProduct = await api.get(`/blink/${uid}/product/${id}`);

      setProduct(resultProduct);
    };
    fetchProduct();
  }, [api, uid, id]);

  if (!product) {
    return null;
  }
  return (
    <Container fluid className="px-1 px-lg-4 mt-2">
      <Row className="gx-1">
        <Col md={12} lg={4} className="pe-initial pe-lg-3 mb-2 mb-lg-0">
          <CarouselProduct
            images={product.images}
            video={product.video}
            newProduct={product.tags?.some((tag) => tag.name === 'new')}
            offerProduct={product.offer_price?.length > 0}
          />
        </Col>
        <Col md={12} lg={8} className="ps-initial ps-lg-3">
          {isMobile ? (
            <StoreProductMobileView
              product={product}
              currency={state.settings.COMPANY_CURRENCY}
              taxesEnabled={state.settings.COMPANY_PRICE_WITH_TAXES}
              hidePrices={hidePrices}
              setQuantityItem={setQuantityItem}
            />
          ) : (
            <StoreProductDesktopView
              product={product}
              currency={state.settings.COMPANY_CURRENCY}
              taxesEnabled={state.settings.COMPANY_PRICE_WITH_TAXES}
              hidePrices={hidePrices}
              setQuantityItem={setQuantityItem}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default StoreProductPage;
