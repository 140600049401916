/* eslint no-console: off */
import React, {createContext, useMemo, useContext, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSession} from './SessionContext';

import {
  request,
  ApiErrorInvalidToken,
  setLocalToken,
  setLocalTokenRefresh,
  resetLocalTokens,
  setImpersonate,
  resetImpersonate,
  getImpersonateKey,
} from './utils';

const ApiContext = createContext();

const ApiProvider = ({children}) => {
  const [, sessionActions] = useSession();

  const wrap = useCallback(
    (fn) =>
      async (...args) => {
        try {
          const res = await fn(...args);
          return res;
        } catch (e) {
          if (e instanceof ApiErrorInvalidToken) {
            sessionActions.finish();

            // @TODO popup a message letting the user know that the session expired
            return null;
          }

          throw e;
        }
      },
    [sessionActions],
  );

  const api = useMemo(
    () => ({
      logout: () => {
        resetLocalTokens();
        resetImpersonate();
      },
      login: async (values) => {
        const data = await request('post', '/token', values);
        setLocalToken(data.access);
        setLocalTokenRefresh(data.refresh);
      },
      impersonate: async (userId) => {
        const data = await request('post', `/impersonate/${userId}/`);
        setImpersonate();
        setLocalToken(data.access);
        setLocalTokenRefresh(data.refresh);
      },
      getSession: wrap(() => request('post', '/session')),
      get: wrap((path, data) => request('get', path, data)),
      post: wrap((path, data) => request('post', path, data)),
      put: wrap((path, data) => request('put', path, data)),
      delete: wrap((path) => request('delete', path)),
    }),
    [wrap],
  );

  useEffect(() => {
    const listener = () => {
      if (getImpersonateKey() !== null) {
        api.logout();
        sessionActions.finish();
      }
    };

    if (getImpersonateKey() !== null) {
      window.addEventListener('beforeunload', listener);
    }

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [api, sessionActions]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('`useApi` must be used within a `ApiProvider`');
  }
  return context;
};

export {ApiContext, ApiProvider, useApi};
