import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import TypeField from '../FormFields/TypeField';

import styles from './LoginFields.module.scss';

const PasswordField = (props) => {
  const [visible, setVisible] = useState(false);
  const {t} = useTranslation('components');

  const toggleShowPassword = () => {
    setVisible((prevState) => !prevState);
  };

  const {className} = props;

  return (
    <Form.Group className={`position-relative ${styles.handlers} ${className}`}>
      <TypeField
        name="password"
        placeholder={t('PasswordField.Placeholder')}
        type={visible ? 'text' : 'password'}
      />
      <button type="button" tabIndex="-1" onClick={toggleShowPassword}>
        <Icon icon={visible ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'} />
      </button>
    </Form.Group>
  );
};

export default PasswordField;
