import React from 'react';
import {useField} from 'formik';
import {Form} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import TextField from '../FormFields/TextField';

import styles from './LoginFields.module.scss';

const UsernameField = (props) => {
  const [, , fieldHelpers] = useField('username');
  const {t} = useTranslation('components');

  const clearUsername = () => {
    fieldHelpers.setValue('');
  };

  const {className} = props;

  return (
    <Form.Group className={`position-relative ${styles.handlers} ${className}`}>
      <TextField name="username" placeholder={t('UsernameField.Placeholder')} />
      <button tabIndex="-1" type="button" onClick={clearUsername}>
        <Icon icon="fa fa-xmark" />
      </button>
    </Form.Group>
  );
};

export default UsernameField;
