import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ShopProduct} from '../../types/ShopProduct';
import {formatMoney} from '../../utils';

import styles from './StoreProduct.module.scss';

interface ProductPriceProps {
  product: ShopProduct;
  currency: string;
  taxesEnabled: boolean;
  hidePrice?: boolean;
}

const ProductPrice = ({product, currency, taxesEnabled, hidePrice}: ProductPriceProps) => {
  const {t} = useTranslation('pages');

  const hasOneDefaultCombination = useMemo(
    () => product.combinations.filter((combination) => product.ref === combination.code || !combination.code).length === 1,
    [product],
  );

  const hasOnePack = useMemo(() => product.packs.length === 1, [product]);

  const hasVolumeDiscount = useMemo(() => product.volume_discounts.length > 0, [product]);

  const renderStrikeThroughPrice = () => {
    if (product.price_range.offer_price.min === product.price_range.offer_price.max) {
      return formatMoney(product.default_price, currency);
    }

    if (hasOneDefaultCombination && hasOnePack && !hasVolumeDiscount) {
      return formatMoney(product.default_price, currency);
    }

    return `${t('StoreProductPage.ProductPrice.from')} ${formatMoney(
      product.price_range.default_price.min,
      currency,
    )}`;
  };

  const renderPrice = () => {
    if (
      product.price_range.default_price.min === product.price_range.default_price.max &&
      product.offer_price
    ) {
      return formatMoney(product.offer_price, currency);
    }

    if (hasOneDefaultCombination && hasOnePack && !hasVolumeDiscount) {
      return formatMoney(product.default_price, currency);
    }

    if (
      product.price_range.default_price.min !== product.price_range.default_price.max &&
      !product.offer_price
    ) {
      return `${t('StoreProductPage.ProductPrice.from')} ${formatMoney(
        product.price_range.default_price.min,
        currency,
      )}`;
    }

    if (!product.offer_price) {
      return formatMoney(product.default_price, currency);
    }

    return `${t('StoreProductPage.ProductPrice.from')} ${formatMoney(
      product.price_range.offer_price.min,
      currency,
    )}`;
  };

  return (
    <div className={styles.productPrice}>
      {hidePrice ? (
        <h3>{t('StoreProductPage.ProductPrice.mustLogin')}</h3>
      ) : (
        <React.Fragment>
          {product.offer_price ? <h5>{renderStrikeThroughPrice()}</h5> : null}
          <h3>
            {renderPrice()}
            {taxesEnabled ? <span>+IVA</span> : null}
          </h3>
          {product.price_for_list ? (
            <p className={styles.priceList}>
              {t('StoreProductPage.ProductPrice.priceList', {
                discount: product.discount,
                price: formatMoney(product.price_for_list, currency),
              })}
            </p>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default ProductPrice;
