import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Col} from 'react-bootstrap';
import {CellProps, Column} from 'react-table';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {CombinationQuantitySetter, ShopProduct} from '../../types/ShopProduct';
import {formatMoney} from '../../utils';
import placeholder from '../../assets/placeholder-image.png';
import {DefaultCombinationInput} from './QuantityInputs';
import {DeleteButton, FavoriteProductButton} from './TableButtons';
import {ExpandedRowToggle} from './ExpandedRow';
import {useApi} from '../../contexts/ApiContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import i18n from '../../translations/i18n';
import NewProductSVG from '../../assets/NewProductSVG';
import OfferProductSVG from '../../assets/OfferProductSVG';

import styles from './ShopProduct.module.scss';
import ProductPrice from './ProductPrice';

const ExpandRowButton: FC<{product: ShopProduct}> = ({product}) => {
  const hasVolumeDiscounts = product.volume_discounts.length > 0;
  const hasMultipleCombinations = product.combinations.length > 1;
  const show = hasVolumeDiscounts || hasMultipleCombinations;

  return <ExpandedRowToggle eventKey={product.ref} disabled={!show} />;
};

const ButtonsColumnCell = (toggleFavorite: (product: ShopProduct) => Promise<void>) => {
  const Component = (props: CellProps<ShopProduct>) => {
    const product = props.row.original;

    return (
      <div className={styles.optionButtons}>
        <ExpandRowButton product={product} />
        <FavoriteProductButton product={product} toggleFavorite={toggleFavorite} />
      </div>
    );
  };
  return Component;
};

const buttonsColumn = (
  toggleFavorite: (product: ShopProduct) => Promise<void>,
): Column<ShopProduct> => ({
  id: 'buttons',
  Cell: ButtonsColumnCell(toggleFavorite),
  maxWidth: 100,
});

const HighlightProductColumnCell = (props: CellProps<ShopProduct>) => {
  const product = props.row.original;
  return (
    <div className={`${styles.highlightCell} text-muted fw-bold`}>
      {product.custom_fields['2bk_show'] ? (
        product.custom_fields['2bk_show']
      ) : (
        <React.Fragment>&nbsp;</React.Fragment>
      )}
    </div>
  );
};
const highlightProductColumn: Column<ShopProduct> = {
  Header: '',
  accessor: 'custom_fields',
  Cell: HighlightProductColumnCell,
  maxWidth: 150,
};

const ImageWithTitleColumnCell = (props: CellProps<ShopProduct>) => {
  const product = props.row.original;
  return (
    <Link to={`/product/${product.id}`} className="text-decoration-none text-reset">
      <div className={styles.imageTitleColumn}>
        <div className={styles.imageContainer}>
          <div>
            <img src={product.images[0] || placeholder} alt={product.name} />
            <div className={styles.newIconColumn}>
              {product.tags?.some((tag) => tag.name === 'new') ? (
                <NewProductSVG className={styles.newIconTable} />
              ) : (
                product.offer_price && <OfferProductSVG className={styles.offerIconTable} />
              )}
            </div>
          </div>
        </div>
        <p className="fw-bold">{product.name}</p>
      </div>
    </Link>
  );
};
const imageWithTitleColumn: Column<ShopProduct> = {
  Header: `${i18n.t('components:ShopProduct.TableColumns.columns.headers.product')}`,
  accessor: 'name',
  Cell: ImageWithTitleColumnCell,
  maxWidth: 250,
};

const PriceRangeColumnCell = (currency: string, taxesEnabled: boolean, hidePrices: boolean) => {
  const Component = (props: CellProps<ShopProduct>) => {
    const product = props.row.original;

    return (
      <ProductPrice
        product={product}
        currency={currency}
        taxesEnabled={taxesEnabled}
        hidePrices={hidePrices}
      />
    );
  };
  return Component;
};
const priceRangeColumn = (
  currency: string,
  hidePrices: boolean,
  taxesEnabled: boolean = false,
): Column<ShopProduct> => ({
  Header: `${i18n.t('components:ShopProduct.TableColumns.columns.headers.price')}`,
  accessor: (originalRow) => originalRow.default_price,
  Cell: PriceRangeColumnCell(currency, taxesEnabled, hidePrices),
});

const defaultCodeColumn: Column<ShopProduct> = {
  Header: `${i18n.t('components:ShopProduct.TableColumns.columns.headers.sku')}`,
  accessor: 'ref',
  maxWidth: 150,
};

const QuantityColumnCell = (setCombinationQuantity?: CombinationQuantitySetter) => {
  const Component = (props: CellProps<ShopProduct>) => {
    const product = props.row.original;

    return (
      <Row className={styles.quantityButtons}>
        <Col md={12} lg={9} className="col-input">
          {setCombinationQuantity ? (
            <DefaultCombinationInput
              product={product}
              setCombinationQuantity={setCombinationQuantity}
              enableTooltip
            />
          ) : (
            <strong>{product.quantity}</strong>
          )}
        </Col>
      </Row>
    );
  };
  return Component;
};

const quantityColumn = (
  setCombinationQuantity?: CombinationQuantitySetter,
): Column<ShopProduct> => ({
  Header: (
    <div className="text-center me-0">
      {i18n.t('components:ShopProduct.TableColumns.columns.headers.quantity')}
    </div>
  ),
  accessor: 'quantity',
  Cell: QuantityColumnCell(setCombinationQuantity),
  maxWidth: 200,
});

const QuantityColumnMobileCell = (setCombinationQuantity?: CombinationQuantitySetter) => {
  const Component = (props: CellProps<ShopProduct>) => {
    const product = props.row.original;

    return (
      <Row className={styles.quantityButtons}>
        <Col xs={12} className="col-input">
          {setCombinationQuantity ? (
            <DefaultCombinationInput
              product={product}
              setCombinationQuantity={setCombinationQuantity}
              enableTooltip
            />
          ) : (
            <strong>{product.quantity}</strong>
          )}
        </Col>
      </Row>
    );
  };
  return Component;
};

const quantityColumnMobile = (
  setCombinationQuantity?: CombinationQuantitySetter,
): Column<ShopProduct> => ({
  Header: `${i18n.t('components:ShopProduct.TableColumns.columns.headers.quantity')}`,
  accessor: 'quantity',
  Cell: QuantityColumnMobileCell(setCombinationQuantity),
});

const subtotalColumn = (currency: string): Column<ShopProduct> => ({
  Header: `${i18n.t('components:ShopProduct.TableColumns.columns.headers.subtotal')}`,
  accessor: (originalRow) => formatMoney(originalRow.total, currency),
});

const ImageWithTitleAndPriceRangeColumnCell = (
  currency: string,
  taxesEnabled: boolean,
  hidePrices: boolean,
  toggleFavorite?: (product: ShopProduct) => Promise<void>,
) => {
  const Component = (props: CellProps<ShopProduct>) => {
    const product = props.row.original;

    return (
      <div className={styles.imageTitleColumn}>
        <div className={styles.imageContainer}>
          <div>
            <img src={product.images[0] || placeholder} alt={product.name} />
            {toggleFavorite && (
              <button
                type="button"
                className={product.favorite ? styles.active : undefined}
                onClick={(event) => {
                  event.stopPropagation();
                  toggleFavorite(product);
                }}
              >
                <Icon icon="heart" />
              </button>
            )}
            {product.tags?.some((tag) => tag.name === 'new') && (
              <OfferProductSVG className={styles.newIconColumn} />
            )}
          </div>
        </div>
        <Link to={`/product/${product.id}`} className="text-decoration-none text-reset">
          <p className="fw-bold">{product.name}</p>
          <p className={styles.sku}>{product.ref}</p>
          <ProductPrice
            product={product}
            currency={currency}
            taxesEnabled={taxesEnabled}
            hidePrices={hidePrices}
          />
        </Link>
      </div>
    );
  };
  return Component;
};

const imageWithTitleAndPriceRangeColumn = (
  currency: string,
  hidePrices: boolean,
  taxesEnabled: boolean = false,
  toggleFavorite?: (product: ShopProduct) => Promise<void>,
): Column<ShopProduct> => ({
  Header: `${i18n.t('components:ShopProduct.TableColumns.columns.headers.product')}`,
  accessor: 'name',
  Cell: ImageWithTitleAndPriceRangeColumnCell(currency, taxesEnabled, hidePrices, toggleFavorite),
  maxWidth: 200,
});

const ExpandRowToggleCell = () => {
  const Component = (props: CellProps<ShopProduct>) => {
    const product = props.row.original;
    return <ExpandRowButton product={product} />;
  };
  return Component;
};

const expandRowToggleColumn = (): Column<ShopProduct> => ({
  id: 'toggle',
  Cell: ExpandRowToggleCell(),
  maxWidth: 70,
});

const ExpandedRowAndDeleteColumnCell = (props: CellProps<ShopProduct>) => {
  const api = useApi();
  const [{uid}, {setCart}] = useEcommerce();
  const {t} = useTranslation('components');
  const product = props.row.original;

  const deleteProduct = useCallback(async () => {
    const response = await api.delete(`/blink/${uid}/${product.id}`);
    setCart(response.cart);
  }, [api, uid, setCart, product.id]);

  return (
    <div className={styles.optionButtons}>
      <ExpandRowButton product={product} />
      <DeleteButton
        deleteProduct={deleteProduct}
        confirmationText={t('ShopProduct.DeleteButton.confirmationTextSingle')}
      />
    </div>
  );
};

const expandedRowAndDeleteColumn: Column<ShopProduct> = {
  id: 'expandedRowAndDelete',
  Cell: ExpandedRowAndDeleteColumnCell,
};

const discountColumn: Column<ShopProduct> = {
  Header: `${i18n.t('components:ShopProduct.TableColumns.columns.headers.discount')}`,
  accessor: 'discount',
};

const priceForListColumn = (currency: string): Column<ShopProduct> => ({
  Header: `${i18n.t('components:ShopProduct.TableColumns.columns.headers.unitPrice')}`,
  accessor: 'price_for_list',
  Cell: (props: CellProps<ShopProduct>) => <div>{formatMoney(props.value, currency)}</div>,
});

export {
  imageWithTitleColumn,
  priceRangeColumn,
  defaultCodeColumn,
  quantityColumn,
  quantityColumnMobile,
  subtotalColumn,
  imageWithTitleAndPriceRangeColumn,
  expandRowToggleColumn,
  expandedRowAndDeleteColumn,
  highlightProductColumn,
  buttonsColumn,
  ExpandRowButton,
  discountColumn,
  priceForListColumn,
};
