import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ShopProduct} from '../../types/ShopProduct';
import {formatMoney} from '../../utils';

import styles from './ShopProduct.module.scss';

interface ProductPriceProps {
  product: ShopProduct;
  currency: string;
  taxesEnabled: boolean;
  hidePrices?: boolean;
}
/**
 * The ProductPrice component is responsible for rendering the price of a product.
 * It displays the default price, offer price, and indicates if taxes (VAT) are included.
 */
const ProductPrice = ({product, currency, taxesEnabled, hidePrices}: ProductPriceProps) => {
  const {t} = useTranslation('components');
  /**
   * Determines if there is exactly one default combination for the product.
   * By default, the combination code it's empty, but due to a corner case, we also verify if
   * it is exactly the same as the product code.
   * Uses useMemo to memoize the result and avoid recalculating on each render.
   */
  const hasOneDefaultCombination = useMemo(
    () =>
      product.combinations.filter(
        (combination) => product.ref === combination.code || !combination.code,
      ).length === 1,
    [product],
  );

  /**
   * Determines if there is exactly one pack for the product.
   * Uses useMemo to memoize the result and avoid recalculating on each render.
   */
  const hasOnePack = useMemo(() => product.packs.length === 1, [product]);

   /**
   * Determines if the product has volume discounts available.
   * Uses useMemo to memoize the result and avoid recalculating on each render.
   */
   const hasVolumeDiscount = useMemo(() => product.volume_discounts.length > 0, [product]);

  /**
   * Renders the strike-through price (original price) based on product conditions.
   * - If the minimum and maximum offer prices are equal
   * - If there is one default combination and one pack
   *    - returns the default price.
   * - Otherwise
   *    - returns the minimum default price in the price range formatted as a string prefixed with "Desde".
   */
  const renderStrikeThroughPrice = () => {
    if (product.price_range.offer_price.min === product.price_range.offer_price.max) {
      return formatMoney(product.default_price, currency);
    }

    if (hasOneDefaultCombination && hasOnePack && !hasVolumeDiscount) {
      return formatMoney(product.default_price, currency);
    }

    return `${t('ShopProduct.TableRow.rows.text.price.from')} ${formatMoney(
      product.price_range.default_price.min,
      currency,
    )}`;
  };

  /**
   * Renders the current price for the table and grid view based on product conditions.
   * - If the minimum and maximum default prices are equal and there is an offer price
   * - If there is one default combination and one pack
   * - If there is no offer price
   *    - returns the default price formatted as a string.
   * - If the minimum and maximum default prices are not equal and there is no offer price
   *    - returns the minimum default price formatted as a string prefixed with "Desde".
   * - Otherwise
   *    - returns the minimum offer price in the price range formatted as a string prefixed with "Desde".
   *
   */
  const renderPrice = () => {
    if (
      product.price_range.default_price.min === product.price_range.default_price.max &&
      product.offer_price
    ) {
      return formatMoney(product.offer_price, currency);
    }

    if (hasOneDefaultCombination && hasOnePack && !hasVolumeDiscount) {
      return formatMoney(product.default_price, currency);
    }

    if (
      product.price_range.default_price.min !== product.price_range.default_price.max &&
      !product.offer_price
    ) {
      return `Desde ${formatMoney(product.price_range.default_price.min, currency)}`;
    }

    if (!product.offer_price) {
      return formatMoney(product.default_price, currency);
    }

    return `${t('ShopProduct.TableRow.rows.text.price.from')} ${formatMoney(
      product.price_range.offer_price.min,
      currency,
    )}`;
  };

  return (
    <div className={styles.price}>
      {hidePrices ? (
        <strong>
          <p>$ {t('ShopProduct.TableRow.rows.text.price.mustLogin')}</p>
        </strong>
      ) : (
        <React.Fragment>
          {product.offer_price && (
            <p className={styles.linethrough}>{renderStrikeThroughPrice()}</p>
          )}
          <p>
            <strong>
              {renderPrice()}
              {taxesEnabled ? <span>+IVA</span> : null}
            </strong>
          </p>
        </React.Fragment>
      )}
    </div>
  );
};

export default ProductPrice;
