import React, {useEffect, useCallback} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router';
import {Row, Container} from 'react-bootstrap';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {useSession} from '../../contexts/SessionContext';
import {useApi} from '../../contexts/ApiContext';
import StoreNavbar from './StoreNavbar';
import StoreFooter from './StoreFooter';
import StoreProductPage from '../StoreProductPage';
import StoreCheckoutPage from '../StoreCheckoutPage';
import StoreCartPage from '../StoreCartPage';
import StoreFavoritePage from '../StoreFavoritePage';
import StoreWhoWeAre from '../StoreWhoWeAre';
import StoreLogin from '../StoreLogin';
import BuyButton from '../../components/BuyButton';
import {asMap, customerAllowedToCheckout, customerAllowedToSeePrices} from '../../utils';
import {ShopGridTable} from '../../components/ShopProduct';
import {ShopProductsContainer} from '../../components/ShopProduct/Container';
import BannerCarousel from '../../components/BannerCarousel';
import StoreUserInformationPage from '../StoreUserInformationPage';
import StoreUserPurchaseOrdersPage from '../StoreUserPurchaseOrdersPage';
import StoreUserPurchaseOrderItemsPage from '../StoreUserPurchaseOrderItemsPage';
import useSessionViewMode from '../../hooks/useSessionViewMode';
import StoreImpersonateUserNotice from './StoreImpersonateUserNotice';
import StoreRegisterUserPage from '../StoreRegisterUserPage';
import StoreRegisterUserSuccessPage from '../StoreRegisterUserSuccessPage';
import useScrollPosition from '../../hooks/useScrollPosition';
import StoreQuickOrderPage from '../StoreQuickOrderPage';
import ProtectedRoutes from '../../components/ProtectedRoutes';

import styles from './StorePage.module.scss';

const StoreIndexPage = () => {
  const [state] = useEcommerce();
  const [storefrontSessionViewMode, setStorefrontSessionViewMode] =
    useSessionViewMode('storefront');

  return (
    <React.Fragment>
      <Row className="mx-0">
        {state.settings.COMPANY_THEME_BANNERS?.length > 0 && (
          <BannerCarousel content={state.settings.COMPANY_THEME_BANNERS} maxHeight={600} />
        )}
      </Row>
      <Container fluid className="px-1 px-lg-4 mt-2">
        {state.title && (
          <Row className="mt-1 gx-2">
            <h1>{state.title}</h1>
          </Row>
        )}
        <ShopProductsContainer productsEndpoint={`/blink/${state.uid}/product/`}>
          <ShopGridTable
            scrollToTop
            sessionViewMode={storefrontSessionViewMode}
            setSessionViewMode={setStorefrontSessionViewMode}
          />
        </ShopProductsContainer>
        <BuyButton />
      </Container>
    </React.Fragment>
  );
};

const StorePage = () => {
  const [state, actions] = useEcommerce();
  const [, , selectors] = useSession();
  const api = useApi();
  const uid = document.BLINK_BLINK_UID;
  const location = useLocation();

  useScrollPosition();

  const anonymousRestrictions = state.settings.COMPANY_ANONYMOUS_CUSTOMER_RESTRICTIONS;

  const isAnonymousAllowed = !(
    anonymousRestrictions === 'NAVIGATE' && selectors.isAnonymousCustomerSession
  );

  const isRegisteredAllowed =
    selectors.isAdminSession || selectors.isSellerSession || selectors.isRegisteredCustomerSession;

  const isUserAllowedToRegister =
    (state.settings.COMPANY_ALLOW_REGISTER_USER && selectors.isAnonymousCustomerSession) || false;

  const isCustomerAllowedToCheckout = customerAllowedToCheckout(state.settings, selectors);

  const isCustomerAllowedToSeePrices = customerAllowedToSeePrices(state.settings, selectors);

  const isNavBarVisible = !(
    location?.pathname.includes('/login') && state.settings.COMPANY_HIDE_HEADER_ON_LOGIN
  );

  const fetchStore = useCallback(
    async (blink_uid) => {
      const blink = await api.get(`/blink/${blink_uid}`);
      // Add a map view on the categories of the company
      blink.categories.map = asMap(blink.categories.list, (category) => category.id);
      // Set the store state to be the store of the blink.
      actions.setStore({...blink, priceList: blink.price_list});
    },
    [actions, api],
  );

  useEffect(() => {
    if (window.HubSpotConversations) {
      if (anonymousRestrictions === 'NAVIGATE' && selectors.isAnonymousCustomerSession) {
        window.HubSpotConversations.widget.remove({resetWidget: true});
      } else {
        window.HubSpotConversations.widget.load();
      }
    }
  }, [anonymousRestrictions, selectors.isAnonymousCustomerSession]);

  useEffect(() => {
    if (!state.uid) {
      fetchStore(uid);
    }
  }, [state.uid, fetchStore, uid]);

  useEffect(() => {
    // Some clients configure HubSpot to show a banner only on the login page.
    // After redirecting from login to the store, the banner may still be present.
    // To ensure it is removed correctly, we reload the page once per session.
    // This should be replaced with a proper HubSpot cleanup in the future.
    const shouldReloadHubspotAtStore =
      state.settings.COMPANY_HUBSPOT && !selectors.isAnonymousCustomerSession;

    if (
      sessionStorage.getItem('hubspotReloadedAfterLogin') !== 'true' &&
      shouldReloadHubspotAtStore
    ) {
      sessionStorage.setItem('hubspotReloadedAfterLogin', true);
      window.location.reload();
    }
  }, [state.settings.COMPANY_HUBSPOT, selectors.isAnonymousCustomerSession]);

  // Make sure that the context is fully loaded
  if (!state.uid) {
    return null;
  }

  return (
    <div className={styles.store}>
      <StoreImpersonateUserNotice />
      {isNavBarVisible && <StoreNavbar />}
      <Container fluid className={styles.storeContent}>
        <Routes>
          <Route element={<ProtectedRoutes isAllowed={isAnonymousAllowed} to="/login" />}>
            <Route element={<ProtectedRoutes isAllowed={isCustomerAllowedToCheckout} to="/" />}>
              <Route path="/checkout" element={<StoreCheckoutPage fetchStore={fetchStore} />} />
            </Route>
            <Route path="/favorites" element={<StoreFavoritePage />} />
            <Route
              element={<ProtectedRoutes isAllowed={isCustomerAllowedToSeePrices} to="/login" />}
            >
              <Route path="/cart" element={<StoreCartPage />} />
            </Route>
            <Route path="/product/:id" element={<StoreProductPage />} />
            <Route
              element={
                <ProtectedRoutes isAllowed={state.settings.COMPANY_ALLOW_QUICK_ORDER} to="/" />
              }
            >
              <Route
                path="/quick-order"
                element={<StoreQuickOrderPage fetchStore={fetchStore} />}
              />
            </Route>
            <Route element={<ProtectedRoutes isAllowed={isRegisteredAllowed} to="/login" />}>
              <Route path="/user/information" element={<StoreUserInformationPage />} />
              <Route path="/user/orders" element={<StoreUserPurchaseOrdersPage />} />
              <Route path="/user/orders/:id" element={<StoreUserPurchaseOrderItemsPage />} />
            </Route>
            <Route index element={<StoreIndexPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="/who-we-are" element={<StoreWhoWeAre />} />
          <Route path="/login" element={<StoreLogin navigateTo="/" />} />
          <Route element={<ProtectedRoutes isAllowed={isUserAllowedToRegister} to="/" />}>
            <Route path="/register">
              <Route index element={<StoreRegisterUserPage />} />
              <Route path="success" element={<StoreRegisterUserSuccessPage />} />
            </Route>
          </Route>
          <Route path="/quick-order" element={<StoreQuickOrderPage />} />
        </Routes>
      </Container>
      <StoreFooter />
    </div>
  );
};

export default StorePage;
